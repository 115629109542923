import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NextPageService } from '@cvx/nextpage';
import { NextPageRoute } from '@cvx/nextpage/public_api';
import { HomeComponent } from './home/home.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { RoleGuard } from './guards/role.guard';
import { MyProfileComponent } from './doa/doaprofile/myprofile.component';
import { PermanentDOAComponent } from './doa/permanent-doa/permanentdoa.component';
import { SearchPermanentDOAComponent } from './search/permanent-doa/searchpermanentdoa.component';
import { SearchTemporaryDOAComponent } from './search/temporary-doa/searchtemporarydoa.component';
import { DirectDelegationAdminComponent } from './report/directdelegationadmin/directdelegationadmin.component';
import { MyDelegationComponent } from './report/mydelegation/mydelegation.component';
import { TemporaryDoaComponent } from './doa/temporary-doa/temporary-doa.component';
import { TemporaryDoaDesigneeComponent } from './doa/temporary-doa-designee/temporary-doa-designee.component';
import { HolidayListComponent } from './admin/holiday_list/holiday_list.component';
import { UpdategrantorComponent } from './admin/updategrantor/updategrantor.component';
import { CommonModule } from '@angular/common';
import { MasterpageComponent } from './report/masterpage/masterpage.component';
import { IntegrityreportComponent } from './report/integrityreport/integrityreport.component';
import { LogreportComponent } from './report/logreport/logreport.component';
import { HistoryreportComponent } from './report/historyreport/historyreport.component';
import { BulkuploadComponent } from './admin/bulkupload/bulkupload.component';
import { ApproveandRevokePermanentDOAComponent } from './admin/approvedoa/approveandrevokepermanentdoa.component';
import { ModifytempararydoaComponent } from './admin/modifytempararydoa/modifytempararydoa.component';
import { UpdatedoacategoriesComponent } from './admin/updatedoacategories/updatedoacategories.component';
import { PermanentdoaadminComponent } from './admin/permanentdoaadmin/permanentdoaadmin.component';
import { AuthService } from './auth.service';
export const routes: NextPageRoute[] = [
  {
    path: '',
    title: 'Home',
    component: HomeComponent,
    //redirectTo: '/',
    //pathMatch: 'full',
  },
  {
    path: 'Home',
    title: 'Home',
    component: HomeComponent,
    //redirectTo: '/',
    //pathMatch: 'full',
  },

  {
    path: 'DOA',
    title: 'Delegation of Authority',
    children: [
      // {
      //   path: "",
      //   title: "Delegation of Authority" ,
      //   component: MyProfileComponent,
      // },
      {
        path: 'MyProfile',
        title: 'DOA Profile',
        component: MyProfileComponent,
      },
      {
        path: 'PermanentDOA',
        title: 'New Permanent DOA',
        component: PermanentDOAComponent,
      },
      {
        path: 'TemporaryDOA',
        title: 'New Temporary DOA',
        component: TemporaryDoaComponent,
        data: { showElement: true, roles: ['PermanentDOAUser', 'Designee'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'MyDesignees',
        title: 'Temporary DOA Designees',
        component: TemporaryDoaDesigneeComponent,
        data: { roles: ['PermanentDOAUser'] },
        canActivate: [RoleGuard],
      },
    ],
  },
  {
    path: 'Search',
    title: 'Search',
    children: [
      {
        path: '',
        title: 'Search',
        component: SearchPermanentDOAComponent,
      },
      {
        path: 'PermanentDOA',
        title: 'Search Permanent DOA',
        component: SearchPermanentDOAComponent,
      },
      {
        path: 'TemporaryDOA',
        title: 'Search Temporary DOA',
        component: SearchTemporaryDOAComponent,
      },
    ],
  },
  {
    path: 'Report',
    title: 'Reports & Approve',
    data: {
      roles: [
        'Grantor',
        'Admin',
        'MasterReportViewer',
        'LogReportViewer',
        'IntegrityReportViewer',
        'HistoryReportViewer',
        'Supervisor',
      ],
    },
    canActivate: [RoleGuard],
    children: [
      {
        path: '',
        title: 'Reports & Approve',
        component: MyDelegationComponent,
        data: { roles: ['Grantor'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'Masterpage',
        title: 'Master Report',
        component: MasterpageComponent,
        data: { roles: ['Admin', 'MasterReportViewer'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'Logreport',
        title: 'Log Report',
        component: LogreportComponent,
        data: { roles: ['Admin', 'LogReportViewer'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'Integrityreport',
        title: 'Integrity Report',
        component: IntegrityreportComponent,
        data: { roles: ['Admin', 'IntegrityReportViewer'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'Historyreport',
        title: 'History Report',
        component: HistoryreportComponent,
        data: { roles: ['Admin', 'HistoryReportViewer'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'MyDelegation',
        title: 'My Delegations',
        component: MyDelegationComponent,
        data: { roles: ['Grantor'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'DirectDelegationAdmin',
        title: 'View Direct Reportees Delegation for Admin',
        component: DirectDelegationAdminComponent,
        data: { roles: ['Supervisor'] },
        canActivate: [RoleGuard],
      },
    ],
  },
  {
    path: 'Admin',
    title: 'Administrator',
    data: { roles: ['Admin'] },
    canActivate: [RoleGuard],
    children: [
      {
        path: 'EnterTemporaryDOA',
        title: 'Enter Temporary DOA',
        component: TemporaryDoaComponent,
        data: { showElement: false },
      },
      {
        path: 'HolidayList',
        title: 'Manage Holiday List',
        component: HolidayListComponent,
        data: { roles: ['Admin'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'Updategrantor',
        title: 'Update Grantor',
        component: UpdategrantorComponent,
      },
      {
        path: 'UpdateCategories',
        title: 'Update DOA Categories',
        component: UpdatedoacategoriesComponent,
      },
      {
        path: 'BulkUpload',
        title: 'Bullk Upload DOA',
        component: BulkuploadComponent,
      },
      {
        path: 'ApproveandRevokePermanentDOA',
        title: 'Approve and Revoke Permanent DOA',
        component: ApproveandRevokePermanentDOAComponent,
      },
      {
        path: 'ModifyTemporaryDOA',
        title: 'Modify Temporary DOA',
        component: ModifytempararydoaComponent,
      },
      {
        path: 'PermanentDOAAdmin',

        component: PermanentdoaadminComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private NP: NextPageService, private authService: AuthService) {
    const roles = this.authService.getEmployeeRoles();
    this.NP.options.navigationRoutes = this.filterRoutes(routes, roles);
  }

  filterRoutes(routes: NextPageRoute[], roles: string[]): NextPageRoute[] {
    return routes
      .filter((route) => {
        if (route.data && route.data.roles) {
          return route.data.roles.some((role) => roles.includes(role));
        }
        return true;
      })
      .map((route) => {
        if (route.children) {
          return {
            ...route,
            children: this.filterRoutes(route.children, roles),
          };
        }
        return route;
      });
  }
}
