import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../services/admin/admin.service';
import { IHolidayList } from '../../interfaces/admin/holidaylist';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AddUpdateHolidayDialogComponent } from './add-update-holiday-dialog/add-update-holiday-dialog.component';
 
@Component({
  selector: 'app-holiday_list',
  templateUrl: './holiday_list.component.html',
  styleUrls: ['./holiday_list.component.css'],
})
export class HolidayListComponent implements OnInit, AfterViewInit {
  title = "Holiday List";
  currentBU = "";
  displayedColumns: string[] = ['formattedDate', 'description', 'actions'];
  dataSource = new MatTableDataSource<IHolidayList>();
  businessUnit: string = '';
  isLoading = false;
 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  private dateFilterValue = '';
  private descriptionFilterValue = '';
  private sortDisabled = false;
  private issorting = true;
 
  constructor(private holidayListService: AdminService, public dialog: MatDialog) {}
 
  ngOnInit(): void {
    const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
    if (employeeInfo && employeeInfo.businessUnit) {
      this.businessUnit = employeeInfo.businessUnit;
      this.currentBU = employeeInfo.businessUnit;
    }
    this.refreshHolidayList();
  }
 
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 
  applyDateFilter(filterValue: string) {
    this.issorting = false;
    this.dateFilterValue = filterValue.trim().toLowerCase();
    this.applyCombinedFilter();
  }
 
  applyDescriptionFilter(filterValue: string) {
    this.descriptionFilterValue = filterValue.trim().toLowerCase();
    this.applyCombinedFilter();
  }
 
  applyCombinedFilter() {
    this.dataSource.filterPredicate = (data: IHolidayList, filter: string) => {
      const dateMatch = `${data.day} - ${this.getMonthName(data.month)}`.toLowerCase().includes(this.dateFilterValue);
      const descriptionMatch = data.description.toLowerCase().includes(this.descriptionFilterValue);
      return dateMatch && descriptionMatch;
    };
    this.dataSource.filter = `${this.dateFilterValue}${this.descriptionFilterValue}`;
  }
 
  disablesort() {
    this.issorting = false;
  }
 
  enablesort() {
    this.issorting = true;
    this.announceSortChange({ active: 'formattedDate', direction: 'asc' });
  }
 
  announceSortChange(event: Sort) {
    if (this.issorting) {
      const data = this.dataSource.data.slice();
      const sortDirection = event.direction;
      const columnName = event.active;
 
      if (sortDirection === '') {
        this.dataSource.data = data;
        return;
      }
 
      data.sort((a, b) => {
        let valueA = a[columnName];
        let valueB = b[columnName];
 
        if (columnName === 'formattedDate') {
          valueA = new Date(a.month - 1, a.day);
          valueB = new Date(b.month - 1, b.day);
        }
 
        const comparison = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        return sortDirection === 'asc' ? comparison : -comparison;
      });
 
      this.dataSource.data = data;
    }
  }
 
  refreshHolidayList(): void {
    this.isLoading = true;
    this.holidayListService.getHolidayList(this.businessUnit).subscribe({
      next: (data: IHolidayList[]) => {
        this.dataSource.data = data;
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
      },
    });
  }
 
  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddUpdateHolidayDialogComponent, {
      width: '400px',
      //position: { top: '10%', left: '10%' },
      data: { isEdit: false },
    });
 
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.refreshHolidayList();
    });
  }
 
  openUpdateDialog(element: IHolidayList): void {
    const dialogRef = this.dialog.open(AddUpdateHolidayDialogComponent, {
      width: '400px',
      data: { isEdit: true, holiday: element },
    });
 
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.refreshHolidayList();
    });
  }
 
  openRemoveDialog(element: IHolidayList): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '300px',
      position: { top: '10%', left: '41%' },
      data: { ID: element.id, message: `Are you sure you want to delete selected holiday details?` },
    });
 
    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.removeHolidayFromUI(element);
    });
  }
 
  removeHolidayFromUI(element: IHolidayList): void {
    const index = this.dataSource.data.indexOf(element);
    if (index !== -1) {
      this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();
    }
  }
 
  getMonthName(month: number): string {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    return monthNames[month - 1];
  }
}