import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { ApproveandRevokePermanentDOAComponent} from './approvedoa/approveandrevokepermanentdoa.component';
import {HolidayListComponent} from '../../app/admin/holiday_list/holiday_list.component';
import {UpdategrantorComponent} from '../../app/admin/updategrantor/updategrantor.component';
import { UpdatedoacategoriesComponent } from "./updatedoacategories/updatedoacategories.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { PermanentdoaadminComponent } from "../admin/permanentdoaadmin/permanentdoaadmin.component";




const componentList = [
    ApproveandRevokePermanentDOAComponent,
    HolidayListComponent,
    UpdategrantorComponent,
    UpdatedoacategoriesComponent,
    PermanentdoaadminComponent
    
];

@NgModule({
    imports: [MenuModule,FormsModule,CommonModule],
    declarations: [componentList],
  })
  export class AdminModule {}