import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrl: './user-information.component.css',
})
export class UserInformationComponent {
  constructor(
    private appComponent: AppComponent,
    private doaService: DelegationOfAuthorityService
  ) {}
  loggedUserEmail = this.appComponent.loggedUserEmail;
  loggedUsername = '';
  supervisorEmail = '';
  jobTitle = '';
  summaryUnit = '';
  businessUnit = '';
  department = '';
  workLocation = '';
  opco = '';
  employeeType = '';

  ngOnInit(): void {
    this.doaService.getUserProfile(this.loggedUserEmail).subscribe((data) => {
      this.loggedUsername = data.name;
      this.loggedUserEmail = data.emailAddress;
      this.supervisorEmail = data.supervisorEmail;
      this.jobTitle = data.jobTitle;
      this.summaryUnit = data.summaryUnit;
      this.businessUnit = data.businessUnit;
      this.department = data.department;
      this.workLocation = data.workLocation;
      this.opco = data.opco;
      this.employeeType = data.employeeType;
    });
  }
}
