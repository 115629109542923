import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-review-dialog',
  //standalone: true,
  //imports: [],
  templateUrl: './review-dialog.component.html',
  styleUrl: './review-dialog.component.css'
})
export class ReviewDialogComponent {
  review: string = '';
  constructor(public dialogRef: MatDialogRef<ReviewDialogComponent>) {}

  onOk(): void {
    this.dialogRef.close(this.review);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
