import { Component } from '@angular/core';
// import { AdminService } from 'src/app/services/admin.service';
import { BulkUploadService } from 'src/app/services/admin/bulk_upload.service';
import { MatDialog } from '@angular/material/dialog';
import { BulkDialogComponent } from 'src/app/bulk-dialog/bulk-dialog.component';
import { AdminService } from 'src/app/services/admin/admin.service';
import { CalAngularService } from '@cvx/cal-angular';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-bulkupload',
  templateUrl: './bulkupload.component.html',
  styleUrl: './bulkupload.component.css'
})
export class BulkuploadComponent {
  /**
   *
   */
  selectedFile:File | null=null;
  currentBU:string="";
  loggedInUser:string="";
  modalMessage:string="";
  isLoading:boolean=false;
  
  //constructor(public adminService: AdminService) {}
  constructor(public adminService:AdminService,public dialog:MatDialog,private authService:CalAngularService,private commonService:CommonService)
  {
    if (this.authService.isUserSignedIn()) {
      var storedData = localStorage.getItem('ReferenceData');
      if (storedData) {
        
        this.currentBU = JSON.parse(storedData).empoyeeInfo.businessUnit;
        this.loggedInUser = JSON.parse(storedData).empoyeeInfo.emailAddress;
            
      }
    }
  }
    
  onFileChange(event:any):void
  {
    const file=event.target.files[0];
    if(file)
    {
      this.selectedFile=file;
    }
       }
  
 
  uploadFileToServer(): void {
    
    if (!this.selectedFile) {
      alert('Please select an excel file.');
      return;
    }  
    const fileExtension = this.selectedFile.name.split('.').pop()?.toLowerCase();
    if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
      alert('Please upload file in .xls/.xlsx format only.');
      return;
    }
    if (this.selectedFile.size == 0) {
      alert('No data uploaded from excel file. Please review the excel file and try again');
      return;
    }
    this.isLoading=true;
    this.adminService.uploadFileToServer(this.selectedFile,this.currentBU,this.loggedInUser).subscribe((data) => {
      this.isLoading=false;
      if (data) {
        
        this.commonService.openModal(data.messageTitle,data.messageDesc);
        

       // this.opensuccessDialog(data.messageDesc);
        //alert('Upload successful');
      }
     
    });
  } 
  

  opensuccessDialog(msg: string): void {
    this.modalMessage = msg;
    const modal = document.getElementById('successModal') as HTMLElement;
    const okBtn = document.getElementById('closeSuccessModal') as HTMLElement;

    modal.style.display = 'block';

    okBtn.onclick = () => {
      modal.style.display = 'none';
    };

    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';
      }
    };
  }

  
}


  // onUpload()
  // {

  // }
  // onFileSelected()
  // {
    
  // }
