import { Component } from '@angular/core';

@Component({
  selector: 'app-masterpage',
  standalone: true,
  imports: [],
  templateUrl: './masterpage.component.html',
  styleUrl: './masterpage.component.css'
})
export class MasterpageComponent {

}
