// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* OK button styles */
#closeSuccessModal {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  bottom: 20px;
}


`, "",{"version":3,"sources":["webpack://./src/app/doa/temporary-doa-designee/confirmdialog/confirmdialog.component.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,aAAa;EACb,eAAe;EACf,UAAU;EACV,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,4EAA4E;AAC9E;;;AAGA,qBAAqB;AACrB;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":["/* Modal styles */\r\n.modal {\r\n  display: none;\r\n  position: fixed;\r\n  z-index: 1;\r\n  left: 50%;\r\n  top: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: auto;\r\n  background-color: transparent;\r\n}\r\n\r\n.modal-content {\r\n  background-color: #fefefe;\r\n  margin: auto;\r\n  padding: 20px;\r\n  border: 1px solid #888;\r\n  width: 80%;\r\n  max-width: 500px;\r\n  height: auto;\r\n  max-height: 300px;\r\n  overflow-y: auto;\r\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\r\n}\r\n\r\n\r\n/* OK button styles */\r\n#closeSuccessModal {\r\n  background-color: #007bff;\r\n  color: white;\r\n  border: none;\r\n  padding: 5px 10px;\r\n  text-align: right;\r\n  text-decoration: none;\r\n  display: inline-block;\r\n  font-size: 14px;\r\n  margin: 10px 0;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  position: absolute;\r\n  right: 20px;\r\n  bottom: 20px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
