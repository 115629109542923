// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  
  /* OK button styles */
  #closeSuccessModal {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: right;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  
  #closeSuccessModal:hover {
    background-color: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/app/bulk-dialog/bulk-dialog.component.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,aAAa;IACb,eAAe;IACf,UAAU;IACV,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,cAAc;IACd,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,4EAA4E;EAC9E;;;EAGA,qBAAqB;EACrB;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Modal styles */\n.modal {\n    display: none;\n    position: fixed;\n    z-index: 1;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: transparent;\n  }\n  \n  .modal-content {\n    background-color: #fefefe;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #888;\n    width: 80%;\n    max-width: 500px;\n    height: auto;\n    max-height: 300px;\n    overflow-y: auto;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n  }\n  \n  \n  /* OK button styles */\n  #closeSuccessModal {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    text-align: right;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 14px;\n    margin: 10px 0;\n    cursor: pointer;\n    border-radius: 4px;\n    position: absolute;\n    right: 20px;\n    bottom: 20px;\n  }\n  \n  #closeSuccessModal:hover {\n    background-color: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
