import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource} from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "../../services/table-sort.services";
import { MatPaginator } from "@angular/material/paginator";
import { FormBuilder,FormControl,FormGroup,Validators } from "@angular/forms";
import { ISearchTemp } from '../../interfaces/search/searchtemp';
import {SearchService} from '../../services/search/search.service';
import { ISearchCriteria } from "src/app/interfaces/search/searchcriteria";
import { CommonService } from '../../services/common/common.service';
import { Router } from '@angular/router';

@Component({
    selector: 'searchtemporarydoa',
    templateUrl: 'searchtemporarydoa.component.html',
    styleUrls: ["searchtemporarydoa.component.css"]
})
export class SearchTemporaryDOAComponent implements OnInit { 

    title = "Search Temporary DOA";
    dataSourceLoading: boolean = false; 
    listingsVisible: boolean = true; 
    public searchCriteria: ISearchCriteria;
    public searchForm: FormGroup;   
    public dataSource: MatTableDataSource<ISearchTemp>;
    public originalDataSource: MatTableDataSource<ISearchTemp>;
    businessUnit:string;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    
    public displayedColumns: string[] = [
      "startDate",
      "endDate",
      "dueTo",
      "toName",
      "to_Division",
      "to_Section",
      "supervisorName",
      "from_Name",
      "createdBy",
      "created",
      "workDays",
      "offDuty"
      ]; 

    constructor(
      private tableSortService: TableSortService, 
      private searchDOADataService: SearchService,  
      private fb: FormBuilder ,
      private commonService: CommonService,
      private router: Router  
    ) {    
    }
    ngOnInit(): void {    
      const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
      if (employeeInfo && employeeInfo.businessUnit) {
        this.businessUnit = employeeInfo.businessUnit;       
      }       
      this.searchForm = this.fb.group({
        status:["All"],
        department: [""], 
        delegateCAI:["",[Validators.email]] ,     
        delegateName:[""],
        assignedReason:["All"],
        delegatorCAI:["",[Validators.email]],
        submittedByCAI:["",[Validators.email]]
      });
   }
   announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }
  clearFilters() {
    this.searchForm.reset({
        status:"All",
        department: "", 
        delegateCAI:"" ,     
        delegateName:"",
        assignedReason:"All",
        delegatorCAI:"",
        submittedByCAI:""
    });
    this.dataSource ? this.dataSource.data = [] : null;
    this.listingsVisible = true;    
  }
  onSearch(){   
    this.dataSourceLoading = true; 
    this.searchCriteria = this.searchForm.value;
    this.searchCriteria.businessUnit= this.businessUnit;
    this.searchDOADataService
    .getSearchTempDOA(this.searchCriteria)
    .subscribe((data:any) => {    
      if(data!=null && data.length>0) 
      {
        this.listingsVisible=false;
        this.dataSource = new MatTableDataSource(data);
        this.originalDataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSourceLoading = false;        
      }
      else{
        this.listingsVisible = true;
        this.dataSourceLoading = false;
        this.dataSource.data=[];
      }       
    });    
  }
  viewLatestPermanentDOA(row){
    var param = {    
      userEmail:row.from
    }
    this.commonService.params=param;
    this.router.navigate(['/DOA/PermanentDOA']);
  }
  applyFilter(filterValue: string, column: string) {
    let currentFilter = this.dataSource.filter ? JSON.parse(this.dataSource.filter) : {};
    currentFilter[column] = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(currentFilter);
    this.dataSource.filterPredicate = (data: ISearchTemp, filter: string) => {
        const transformedFilter = JSON.parse(filter);
        return Object.keys(transformedFilter).every(key => {
            if (key === 'created' || key === 'startDate' || key === 'endDate') {
                const date = new Date(data[key]);
                const formattedDate = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
                return formattedDate.toLowerCase().includes(transformedFilter[key]);
            } else if (key === 'workDays' || key === 'offDuty') {
                return data[key].toString().toLowerCase().includes(transformedFilter[key]);
            }
            return data[key].toLowerCase().includes(transformedFilter[key].toLowerCase());
        });
    };
    this.dataSource.filter = JSON.stringify(currentFilter);
}
 
}
  
