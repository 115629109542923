import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';

const scope = environment.scope;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  scope: any;

  constructor(private authService: CalAngularService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        if (token) {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
          return next.handle(cloned);
        } else {
          return next.handle(req);
        }
      })
    );
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.scope = [scope];
      this.authService.getAADToken(this.scope).subscribe(
        (data) => {
          resolve(data.toString());
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
