import { Component } from '@angular/core';

@Component({
  selector: 'app-historyreport',
  standalone: true,
  imports: [],
  templateUrl: './historyreport.component.html',
  styleUrl: './historyreport.component.css'
})
export class HistoryreportComponent {

}
