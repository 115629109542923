import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "../shared.module";
import { HeaderComponent, MenuComponent } from "@cvx/nextpage";
import { AppRoutingModule } from "../app-routing.module"

const componentList = [MenuComponent, HeaderComponent];

@NgModule({
  imports: [BrowserAnimationsModule, SharedModule,AppRoutingModule],
  declarations: [componentList],

  exports: [
    MenuComponent,
    HeaderComponent,      
    SharedModule,  
    AppRoutingModule,
  ],
})
export class MenuModule {}
