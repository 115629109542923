// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
    display: block;
    margin: 0 auto;
  }


  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/holiday_list/add-update-holiday-dialog/add-update-holiday-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;EAChB;;;EAGA;IACE,UAAU;IACV,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".loading-spinner {\n    display: block;\n    margin: 0 auto;\n  }\n\n\n  .error-message {\n    color: red;\n    font-size: 14px;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
