import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-dialog',
  templateUrl: './bulk-dialog.component.html',
  styleUrl: './bulk-dialog.component.css'
})
export class BulkDialogComponent {
  
  
constructor(public dialogRef:MatDialogRef<BulkDialogComponent>)
{}
  onclose():void{
    this.dialogRef.close();
  
}
}

