import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ISearchPermanent } from "../../interfaces/search/searchpermanent";
import { Observable } from 'rxjs';
import { ISearchTemp } from '../../interfaces/search/searchtemp';
import { ISearchCriteria } from "src/app/interfaces/search/searchcriteria";
import { ISearchInput } from "src/app/interfaces/search/searchinput";
const apiUrl = environment.apiUrl;
const currentapiBU = environment.BusinessUnit;

@Injectable({
  providedIn: "root",
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public getSearchTempDOA(searchCriteria:ISearchCriteria): Observable<ISearchTemp[]> {  
    return this.http.post<ISearchTemp[]>(
      `${apiUrl}/Search?currentBU=${searchCriteria.businessUnit}`,
      searchCriteria
    );
  }
  public getSearchPermanentDOA(searchCriteria:ISearchInput): Observable<ISearchPermanent[]> {  
    return this.http.post<ISearchPermanent[]>(
      `${apiUrl}/Search/GetSearchPermanentDOA?currentBU=${searchCriteria.businessUnit}`,
      searchCriteria
    );
  }
  public searchApproveRevokeDOA(searchCriteria:ISearchInput): Observable<ISearchPermanent[]> {  
   return this.http.post<ISearchPermanent[]>(
    `${apiUrl}/Search/SearchApproveRevokeDOA?currentBU=${searchCriteria.businessUnit}`,
     searchCriteria
   );
 }
}
    
