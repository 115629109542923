// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    margin-top: 5px;
  }
   



`, "",{"version":3,"sources":["webpack://./src/app/doa/permanent-doa/confirm-popup/confirm-popup-component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,eAAe;EACjB","sourcesContent":[".mat-dialog-container {\n    display: flex;\n    justify-content: center;\n    align-items: flex-start; \n    margin-top: 5px;\n  }\n   \n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
