import { NgModule } from '@angular/core';
import { MyProfileComponent } from './doaprofile/myprofile.component';
import { PermanentDOAComponent } from './permanent-doa/permanentdoa.component';
import { MenuModule } from '../menu/menu.module';
import { TemporaryDoaComponent } from './temporary-doa/temporary-doa.component';
import { TemporaryDoaDesigneeComponent } from './temporary-doa-designee/temporary-doa-designee.component';
import { UserInformationComponent } from './user-information/user-information.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './temporary-doa-designee/confirmdialog/confirmdialog.component';
import { ReviewDialogComponent } from './permanent-doa/review-dialog/review-dialog.component';
import { ConfirmPopupComponent } from './permanent-doa/confirm-popup/confirm-popup-component';

const componentList = [
  MyProfileComponent,
  PermanentDOAComponent,
  TemporaryDoaComponent,
  TemporaryDoaDesigneeComponent,
  UserInformationComponent,
  ConfirmationDialogComponent,
  ReviewDialogComponent,
  ConfirmPopupComponent
];

@NgModule({
  imports: [MenuModule,FormsModule,ReactiveFormsModule],
  declarations: [componentList],
})
export class DOAModule {}
