import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { AdminService } from '../../../services/admin/admin.service';
 
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent {
  businessUnit: string = ''; 
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private commonService:CommonService
  ) {
    const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
    if (employeeInfo && employeeInfo.businessUnit) {
      this.businessUnit = employeeInfo.businessUnit;
    }

  }
 
  onCancel(): void {

    this.dialogRef.close(false);

  }
 
  onConfirm(): void {
    this.adminService.deleteHoliday(this.data.ID, this.businessUnit).subscribe({
      next: response => {
        this.commonService.openModal('Message','Holiday details removed successfully!');
        this.dialogRef.close(true);
      },
      error: error => {
        console.error('Error deleting holiday:', error);
        this.dialogRef.close(false);
      }
    });
  }

}

 