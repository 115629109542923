import { NgModule } from "@angular/core";
import { MenuModule } from "../menu/menu.module";
import { SearchTemporaryDOAComponent} from '../search/temporary-doa/searchtemporarydoa.component';
import { SearchPermanentDOAComponent} from '../search/permanent-doa/searchpermanentdoa.component';

const componentList = [
    SearchPermanentDOAComponent,
    SearchTemporaryDOAComponent
];

@NgModule({
    imports: [MenuModule],
    declarations: [componentList],
  })
  export class SearchModule {}