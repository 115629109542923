import { Injector, NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MenuModule } from './menu/menu.module';
import { SearchModule } from "./search/search.module";
import { AdminModule } from "./admin/admin.module";
import { environment } from "../environments/environment";
import { DOAModule } from './doa/doa.module';
import { CalAngularModule } from "@cvx/cal-angular";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableSortService } from "./services/table-sort.services";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BulkDialogComponent } from './bulk-dialog/bulk-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddUpdateHolidayDialogComponent } from './admin/holiday_list/add-update-holiday-dialog/add-update-holiday-dialog.component';
import { ConfirmDialogComponent } from './admin/holiday_list/confirm-dialog/confirm-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { DirectDelegationAdminComponent } from './report/directdelegationadmin/directdelegationadmin.component';
import { ReportserviceService } from 'src/app/services/reportservice.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { ModifytempararydoaComponent } from './admin/modifytempararydoa/modifytempararydoa.component';
import { MyDelegationComponent } from './report/mydelegation/mydelegation.component';
import { AuthInterceptor } from './auth.interceptor';
import { DoaInfoModalComponent } from './doa-info-modal/doa-info-modal.component';
import { AuthService } from './auth.service';  
import { RoleGuard } from './guards/role.guard'; 
import { BulkuploadComponent } from './admin/bulkupload/bulkupload.component';
const componentList = [
  AppComponent,
  HomeComponent  
];
@NgModule({
  declarations: [componentList,AddUpdateHolidayDialogComponent,
    ConfirmDialogComponent,HomeComponent,DirectDelegationAdminComponent,ModifytempararydoaComponent,MyDelegationComponent,DoaInfoModalComponent,BulkuploadComponent],
imports: [
CommonModule,
FormsModule,
BrowserModule,
HttpClientModule,
MenuModule,
SearchModule, 
DOAModule,
AdminModule,
MatSortModule,
MatTooltipModule,
ReactiveFormsModule,
MatTableModule,
MatPaginator,
MatIconModule,
CalAngularModule.forRoot('assets/config.json')],
  providers: [TableSortService,AuthService,RoleGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})


@NgModule({
  declarations:[
    AppComponent,BulkDialogComponent],
  imports:[BrowserModule,HttpClientModule,MatButtonModule,MatDialogModule,MatProgressSpinnerModule,FormsModule], providers: [],
  bootstrap: [AppComponent]  ,
  
})
 
export class AppTest {}
