import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEmpDetails } from '../interfaces/reports/empdetails';

interface EmployeeDto {
    name: string;
}
const localUrl = environment;
@Injectable({
    providedIn: 'root'
})
export class ReportserviceService {
   constructor(private http: HttpClient) {}
      public getEmployeeData(supervisorEmails: string): Observable<EmployeeDto[]>{
        let params = new HttpParams().set('supervisorEmails', supervisorEmails);
        return this.http.get<any>(ReportsAndApproveUrls.Reports, { params });
      } 
      
      public getDOAProfile(email: string): Observable<any> {
        let params = new HttpParams().set('email', email);
        return this.http.get<any>(ReportsAndApproveUrls.tempdoa, { params });
      }
      public getEmployeeDetails(emailIds: string, currentBU: string, supervisorEmail: string): Observable<IEmpDetails[]> {
        return this.http.get<IEmpDetails[]>(`${localUrl.apiUrl}/ReportsAndApprove/GetAuthorityLine?emailIds=${emailIds}&currentBU=${currentBU}&supervisorEmail=${supervisorEmail}`);
      }

      public GetPermanentDOARequestsByGrantorEmailAndStatus(grantorEmail: string,statusCodeId: number, currentBU :string) {
        return this.http.get(`${localUrl.apiUrl}/ReportsAndApprove/GetPermanentDOARequestsByGrantorEmailAndStatus?grantorEmail=${grantorEmail}&statusCodeId=${statusCodeId}&currentBU=${currentBU}`);
      }
    
      public getMasterData(currentBU:string,grantorEmails:string){
        return this.http.get(`${localUrl.apiUrl}/Admin/GetMasterList?currentBU=${currentBU}&grantorEmails=${grantorEmails}`);
      }
      public BulkAprovePermanentDOA(selectedRequests: any[]) {
        return this.http.post(`${localUrl.apiUrl}/ReportsAndApprove/BulkAprovePermanentDOA`,selectedRequests);
    }
    public BulkRejectPermanentDOAReq(selectedRequests: any[]): Observable<any> {
      return this.http.post(`${localUrl.apiUrl}/ReportsAndApprove/BulkRejectPermanentDOAReq`,selectedRequests);
    }
    public getCategoryDOA(email:string):Observable<any> {
      return this.http.get<any[]>(ReportsAndApproveUrls.CategoryDOA()+ `?email=${email}`);
    }
  
}
export const ReportsAndApproveUrls = {
  Reports: `${localUrl.apiUrl}/ReportsAndApprove`,
  Authoritylines:`${localUrl.apiUrl}/ReportsAndApprove/GetAuthorityLine`,
  tempdoa: `${localUrl.apiUrl}/DelegationOfAuthority/GetDOAProfile`,
  CategoryDOA: () => `${localUrl.apiUrl}/ReferenceData`,
}