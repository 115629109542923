import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,
} from "@cvx/nextpage";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgGridAngular } from "ag-grid-angular";
import { MaterialModule } from "./material.module";
import { ValidationComponent } from "./validation/validation.component";
const sharedComponents = [
  SearchComponent,
  FooterComponent,
  TabbedComponent,
  CardSliderComponent,
  SliderComponent,
  DrawerComponent,
  NpSetLayoutDirective,
  NpSelectDirective,
  NpTableDirective,
  ValidNextPageRoutePipe,
  AlertComponent,  
  ValidationComponent  
];

@NgModule({
  declarations: [sharedComponents],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AgGridAngular,
    MaterialModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  exports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    AgGridAngular,
    ReactiveFormsModule,
    MaterialModule,
    sharedComponents,
  ],
})
export class SharedModule {}
