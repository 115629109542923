import { Component } from '@angular/core';

@Component({
  selector: 'app-logreport',
  standalone: true,
  imports: [],
  templateUrl: './logreport.component.html',
  styleUrl: './logreport.component.css'
})
export class LogreportComponent {

}
