import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { ICategoryModel } from '../../interfaces/admin/categorymodel';
import { IDOATypeModel } from '../../interfaces/admin/doatypemodel';
import { IReferenceData } from 'src/app/interfaces/admin/referencedata';
import { IModifytempdoa } from 'src/app/interfaces/admin/modifytempdoa';
import { IHolidayList } from '../../interfaces/admin/holidaylist';
import { IDOACategoryRowForUpdateDto, IDoaType } from 'src/app/interfaces/admin/DOACategoryRowForUpdate';
import { ISearchTemp } from 'src/app/interfaces/search/searchtemp';
import { ISearchCriteria } from 'src/app/interfaces/search/searchcriteria';
import { UpdateGrantorAndAttachmentSubmitDTO } from 'src/app/interfaces/admin/updategrantor';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  [x: string]: any;
  constructor(private http: HttpClient) { }

  // Get list of holidays
  getHolidayList(businessUnit: string): Observable<IHolidayList[]> {
    return this.http.get<IHolidayList[]>(`${apiUrl}/Admin/GetHolidayList?currentBU=${businessUnit}`);
  }

  // Add a new holiday
  addHoliday(holiday: IHolidayList): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${apiUrl}/Admin/AddHolidayListRow?currentBU=${holiday.businessUnit}`, holiday, { headers });
  }

  // Update an existing holiday
  updateHoliday(holiday: IHolidayList): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${apiUrl}/Admin/UpdateHolidayListRow?currentBU=${holiday.businessUnit}`, holiday, { headers });
  }

  // Delete a holiday
  deleteHoliday(ID: number, businessUnit: string): Observable<any> {
    return this.http.delete(`${apiUrl}/Admin/DeleteHolidayListRow?rowId=${ID}&currentBU=${businessUnit}`);
  }

  public getTemporaryDoa(): Observable<IModifytempdoa[]> {
    return this.http.get<IModifytempdoa[]>(`${apiUrl}/Admin/GetTemporaryDoaList`);
  };

  public getUpdateCategoryData(businessUnit: any): Observable<any> {
    return this.http.get<IDOACategoryRowForUpdateDto[]>(`${apiUrl}/Admin/GetDOACategoriesForUpdate?currentBU=${businessUnit}`);
  }

  public uploadFileToServer(file: File,businessUnit: string, loggedInUser: string ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('currentBU', businessUnit);
    formData.append('loggedInUser', loggedInUser);
    

    return this.http.post(`${apiUrl}/Admin/BulkUpload`, formData);
    // return this.http.post(this.apiUrl,formData);
  }
  public getSearchDropDownValues(): Observable<ICategoryModel[]> {
    return this.http.get<ICategoryModel[]>(`${apiUrl}/Admin`);
  }
  public getAuthorityLineData(): Observable<IDOATypeModel[]> {
    return this.http.get<IDOATypeModel[]>(`${apiUrl}/Admin/GetAuthorityLineData`);
  }
  public UpdateCategoryDOATypes(elementData: IDOACategoryRowForUpdateDto[], businessUnit: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(`${apiUrl}/Admin/UpdateCategoryDOATypes?currentBU=${businessUnit}`, elementData, { headers });
  }
  public UpdateDOATypes(elementData: IDOACategoryRowForUpdateDto[], businessUnit: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(`${apiUrl}/Admin/UpdateDOATypes?currentBU=${businessUnit}`, elementData, { headers });
  }
  public deletetempDOA(redelegationID: number, currentBU: string): Observable<any> {
    return this.http.delete(`${apiUrl}/Admin/DeleteTempDOA?redelegationID=${redelegationID}&currentBU=${currentBU}`);
  }

  // Get Attachments List of Update Grantor
  public getUpdateGrantorAttachmentsList(businessUnit: string, emailId: string | null,grantorEmails: string | null, documentIds: string | null): Observable<any> {
    return this.http.get(`${apiUrl}/Admin/GetUpdateGrantorAttachmentsList?currentBU=${businessUnit}&emailIds=${emailId}&grantorEmails=${grantorEmails}&documentIds=${documentIds}`);
  }
  // Update grantor & attachement file of Update Grantor
  public updateGrantorDetails(file: File, updatedRowObject: UpdateGrantorAndAttachmentSubmitDTO): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('updatedRows', JSON.stringify(updatedRowObject.updatedRows));
   
    return this.http.post<any>(`${apiUrl}/Admin/UpdateGrantorAndAttachment`, formData, {
      headers: {
        'enctype': 'multipart/form-data'
      }
    });
  }
}






