export const environment = {
  production: false,
  groupName: "--",
  BusinessUnit:"SOUTHERN AFRICA SBU",
  apiUrl: "https://globaldoa-api-test.azure.chevron.com/api",
  scope:"https://doacon-test.azure.chevron.com/user_impersonation"
  
  
  
};
