import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource} from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { TableSortService } from "../../services/table-sort.services";
import { MatPaginator } from "@angular/material/paginator";
import { FormBuilder,FormControl,FormGroup,Validators } from "@angular/forms";
import { ISearchPermanent } from "src/app/interfaces/search/searchpermanent";
import {SearchService} from '../../services/search/search.service';
import { ISearchInput } from "src/app/interfaces/search/searchinput";
import { IReferenceData } from "src/app/interfaces/admin/referencedata";
import { IStatus } from "src/app/interfaces/admin/status";
import { ICategoryModel } from "src/app/interfaces/admin/categorymodel";
import { IDOATypeModel } from "src/app/interfaces/admin/doatypemodel";
import { CommonService } from '../../services/common/common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'searchpermanentdoa',
  templateUrl: './searchpermanentdoa.component.html',
  styleUrls: ['./searchpermanentdoa.component.css']
})
export class SearchPermanentDOAComponent implements OnInit {
  title = "Search Permanent DOA";  
  dataSourceLoading: boolean = false; 
  listingsVisible: boolean = true; 
  public searchInput: ISearchInput;
  searchForm: FormGroup;   
  referenceData:IReferenceData;
  dataSource: MatTableDataSource<ISearchPermanent>;
  originalDataSource: MatTableDataSource<ISearchPermanent>;
  lstCategory: ICategoryModel[];
  lstDOAType:IDOATypeModel[];
  lstDOATypeFilter:IDOATypeModel[];

  businessUnit:string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
    public displayedColumns: string[] = [
    "effectiveDate",
    "status",
    "typeName",
    "empEmail",
    "employeeName",
    "amount",
    "division",
    "section",
    "grantorEmail",
    "grantorName",
    "supervisorEmail"   
    ]; 

  constructor(
    private tableSortService: TableSortService, 
    private searchDOADataService: SearchService,     
    private fb: FormBuilder ,
    private commonService: CommonService,
    private router: Router     
  ) {    
  }
  ngOnInit(): void {    
    const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
    if (employeeInfo && employeeInfo.businessUnit) {
      this.businessUnit = employeeInfo.businessUnit;       
    }        
    this.searchForm = this.fb.group({
    statusDesc: 'All',
    authorityLine: 'All',
    authorityLineCode: 0,
    department: '',
    grantorEmail: ['',[Validators.email]],
    categoryName: 'All',
    categoryCode: 0,
    employeeEmail: ['',[Validators.email]]
    });
    this.searchInput = this.searchForm.value;
    var storedData = localStorage.getItem('ReferenceData');
      if (storedData) {
       this.referenceData= JSON.parse(storedData).referenceData; 
       this.lstCategory = this.referenceData.categoryList;
       this.lstDOAType = this.referenceData.doaTypeList;
       this.lstDOATypeFilter = this.referenceData.doaTypeList;     
      }   
      console.log(this.referenceData);
    }
 
 announceSortChange(event: Sort) {
  this.dataSource.data = this.tableSortService.sortDataSource(
    this.originalDataSource.data,
    event
  );
}
clearFilters() {
  this.searchForm.reset({
    statusDesc: 'All',
    categoryName: 'All',
    authorityLine: 'All',
    authorityLineCode: 0,
    department: '',
    grantorEmail: '',   
    categoryCode: 0,
    employeeEmail: ''   
  });
  this.searchInput=this.searchForm.value;
  this.dataSource ? this.dataSource.data = [] : null;
  this.listingsVisible = true;    
}
onSearch(){  
  this.dataSourceLoading = true; 
  this.searchInput.employeeEmail= this.searchForm.get("employeeEmail").value;
  this.searchInput.grantorEmail= this.searchForm.get("grantorEmail").value;
  this.searchInput.department= this.searchForm.get("department").value;
  this.searchInput.businessUnit= this.businessUnit;
  this.searchDOADataService
    .getSearchPermanentDOA(this.searchInput)
    .subscribe((data:any) => {
      if(data!=null && data.length>0) 
      {
        this.listingsVisible = false;       
        this.dataSource = new MatTableDataSource(data);
        this.originalDataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSourceLoading = false;
      }    
      else{
        this.dataSourceLoading = false;
        this.listingsVisible = true;      
        this.dataSource.data=[];
      }      
    }); 
}
changeCategory(category) {  
  if (category === 'All') {
      this.searchInput.categoryName = category;
      this.searchInput.categoryCode = 0;
      this.lstDOAType = this.lstDOATypeFilter;
  } else {
      const selectedCategory = this.lstCategory.find(cat => cat.categoryName === category);
      if (selectedCategory) {
          this.searchInput.categoryName = selectedCategory.categoryName;
          this.searchInput.categoryCode = selectedCategory.id;
          this.lstDOAType = this.lstDOATypeFilter.filter(item => item.categoryId === selectedCategory.id);
      }
  }
  this.searchInput.authorityLine = 'All';
  this.searchInput.authorityLineCode = 0;
}
changeAuthorityLine(doaType) { 
  if (doaType == 'All') {
      this.searchInput.authorityLine = doaType;
      this.searchInput.authorityLineCode = 0;
  }
  else {   
    this.searchInput.authorityLine = doaType;
    this.searchInput.authorityLineCode = this.lstDOATypeFilter.find(cat => cat.typeName === doaType).id;
  }
}
onStatusChange(status) { 
  this.searchInput.statusDesc=status;  
}
viewPermanentDOA(row){
 var param = {
    requestId: 0,
    typeCode:row.typeCodeId,
    empEmail: row.empEmail,
    action: 'View',
    userEmail:undefined
  }
  this.commonService.params=param;
  this.router.navigate(['/DOA/PermanentDOA']);
}
// applyFilter(filterValue: string, column: string) {
//   this.dataSource.filterPredicate = (data: ISearchPermanent, filter: string) => {
//     const transformedFilter = JSON.parse(filter);
//     const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
//     return dataValue.includes(transformedFilter[column].toLowerCase());
//   };
//   const filterObject = { [column]: filterValue };
//   this.dataSource.filter = JSON.stringify(filterObject);
// }
applyFilter(filterValue: string, column: string) {
  let currentFilter = this.dataSource.filter ? JSON.parse(this.dataSource.filter) : {};
  currentFilter[column] = filterValue.toLowerCase();
  this.dataSource.filter = JSON.stringify(currentFilter);
  this.dataSource.filterPredicate = (data: ISearchPermanent, filter: string) => {
      const transformedFilter = JSON.parse(filter);
      return Object.keys(transformedFilter).every(key => {
          if (key === 'effectiveDate') {
              const date = new Date(data[key]);
              const formattedDate = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
              return formattedDate.toLowerCase().includes(transformedFilter[key]);
          }
          return data[key].toLowerCase().includes(transformedFilter[key].toLowerCase());
      });
  };
  this.dataSource.filter = JSON.stringify(currentFilter);
}

}