import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../doa/temporary-doa-designee/confirmdialog/confirmdialog.component';

@Injectable({
    providedIn: 'root'
  })
  export class PrintService {
  
    constructor(private dialog: MatDialog) {}
  
    printPage(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        height: '150px',
        width: '500px'
      });
      dialogRef.componentInstance.confirmMessage = "Please print the contents in Landscape mode for better visibility. Change the settings of orientation using preferences button of print dialog. Click Yes to print.";
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.hideElements();
          setTimeout(() => {
            window.print();
            setTimeout(() => {
              this.showElements();
            }, 200);
          }, 200);
        }
      });
    }
  
    private hideElements(): void {
      const elementsToHide = ['buttonDiv', 'topMenu', 'topHeader', 'menuBar', 'footerBar'];
      elementsToHide.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'none';
        }
      });
    }
  
    private showElements(): void {
      const elementsToShow = ['buttonDiv', 'topMenu', 'topHeader', 'menuBar', 'footerBar'];
      elementsToShow.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = 'block';
        }
      });
    }
  }