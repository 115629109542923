// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container2{
    background-color: #F1F1F1;
    border:solid 1px #d4d4d4;
    border-radius:5px;
    margin-bottom:15px;
    padding:50px;
}

.title{
    font-family: "GothamBold",Arial,sans-serif;
}

.content{
    font-family:"GothamNarrowBook",Arial,sans-serif ;
}`, "",{"version":3,"sources":["webpack://./src/app/doa/user-information/user-information.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gDAAgD;AACpD","sourcesContent":[".container2{\n    background-color: #F1F1F1;\n    border:solid 1px #d4d4d4;\n    border-radius:5px;\n    margin-bottom:15px;\n    padding:50px;\n}\n\n.title{\n    font-family: \"GothamBold\",Arial,sans-serif;\n}\n\n.content{\n    font-family:\"GothamNarrowBook\",Arial,sans-serif ;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
