import { Component } from '@angular/core';

@Component({
  selector: 'app-integrityreport',
  standalone: true,
  imports: [],
  templateUrl: './integrityreport.component.html',
  styleUrl: './integrityreport.component.css'
})
export class IntegrityreportComponent {

}
