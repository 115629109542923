import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { IDOACategoryRowForUpdateDto, IDoaType } from 'src/app/interfaces/admin/DOACategoryRowForUpdate';

import { AdminService } from 'src/app/services/admin/admin.service';
import { CommonService } from 'src/app/services/common/common.service';



@Component({
  selector: 'app-updatedoacategories',
  // standalone: true,
  // imports: [CommonModule, FormsModule],

  templateUrl: './updatedoacategories.component.html',
  styleUrl: './updatedoacategories.component.css'
})
export class UpdatedoacategoriesComponent {
  title = "Update DOA Categories";
 // router: Router;
  email: string;
  businessUnit: string;
  categories: IDOACategoryRowForUpdateDto[];
  categoriesToBeUpdated: IDOACategoryRowForUpdateDto[];
  DOATypesToBeUpdated: IDoaType[];
  updateDOACategoriesForm: any;
  updatedCategories: Set<number> = new Set<number>();
  updatedTypes: Set<number> = new Set<number>();
  isLoading = false;
  isEditMode = false;
  originalCategories: IDOACategoryRowForUpdateDto[];
  constructor(private adminService: AdminService, private authService: CalAngularService, private snackBar: MatSnackBar, private router: Router,private commonService:CommonService) {

  }
  trackByFn(index: number, item: any): number {
    return index; // or item.id if your items have unique ids
  }

  ngOnInit(): void {
    const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
    this.isLoading = true; 
    if (employeeInfo && employeeInfo.businessUnit) {
      this.businessUnit = employeeInfo.businessUnit;

      this.adminService.getUpdateCategoryData(this.businessUnit).subscribe((data) => {
        if (data) {

          this.categories = data;
          this.originalCategories = JSON.parse(JSON.stringify(data));
          this.isLoading = false; 
        }
        console.log(this.categories);
      });
    }

  }
  markCategoryAsUpdated(id: number): void {
    this.updatedCategories.add(id);
  }
  markTypeAsUpdated(id: number): void {
    this.updatedTypes.add(id);
  }

  toggleEditMode(): void {
    this.isEditMode = true;
  }
  
  cancelEdit(): void {
    this.isEditMode = false;
    this.categories = JSON.parse(JSON.stringify(this.originalCategories));
    this.updatedCategories.clear();
    this.updatedTypes.clear();
  }


  onSubmit() {
    this.isLoading = true;
    const updatedDoaTypes: IDOACategoryRowForUpdateDto[] = this.categories
      .map(category => ({
        ...category,
        loggedInUserEmail: this.authService.cvxClaimsPrincipal.email,
        doaTypes: category.doaTypes.map(doaType => ({
          ...doaType,
          loggedInUserEmail: this.authService.cvxClaimsPrincipal.email,
          businessUnit: this.businessUnit
        }))
      }));
    const updatedCategories: IDOACategoryRowForUpdateDto[] = this.categories
      .filter(category => this.updatedCategories.has(category.id))
      .map(category => ({
        ...category,
        loggedInUserEmail: this.authService.cvxClaimsPrincipal.email,
        doaTypes: category.doaTypes.map(doaType => ({
          ...doaType,
          loggedInUserEmail: this.authService.cvxClaimsPrincipal.email,
          businessUnit: this.businessUnit
        }))
      }));
      const updatedDoaTypesOnly = updatedDoaTypes
      .map(category => ({
          ...category,
          doaTypes: category.doaTypes.filter(doaType => this.updatedCategories.has(doaType.id))
      }))
      .filter(category => category.doaTypes.length > 0);

    console.log('updatedCategories', updatedCategories);
    console.log('updatedDoaTypes', updatedDoaTypes);
    console.log('updatedDoaTypesOnly', updatedDoaTypesOnly);

   const categoriesToSend = updatedCategories.length > 0 ? updatedCategories : [];
 const doaTypesToSend = updatedDoaTypes.length > 0 ? updatedDoaTypes : [];
 const combinedPayload = [...categoriesToSend, ...doaTypesToSend];

 if (combinedPayload.length > 0) {
  this.adminService.UpdateCategoryDOATypes(combinedPayload, this.businessUnit).subscribe(
    (data) => {
      this.isLoading = false;
      if (data) {
        this.commonService.openModal('Message', 'Data Updated Successfully');
        this.isEditMode = false;
        this.originalCategories = JSON.parse(JSON.stringify(this.categories));
        this.router.navigate(['Home']);
      }
    },
    (error) => {
      this.isLoading = false;
      this.commonService.openModal('Message', 'Failed to Update Data');
      console.error('Update failed:', error);
      console.error('Error details:', error.error);
    }
  );
 if (categoriesToSend.length > 0) {
 this.adminService.UpdateCategoryDOATypes(categoriesToSend, this.businessUnit).subscribe(
   (data) => {
     this.isLoading = false; 
     if (data) {
       this.isEditMode = false;
       this.originalCategories = JSON.parse(JSON.stringify(this.categories));
       this.router.navigate(['Home']);
     }
   },
   (error) => {
     this.isLoading = false; 
     console.error('Update failed:', error);
     console.error('Error details:', error.error); 

   });
 }else if (doaTypesToSend.length > 0) {
     this.adminService.UpdateDOATypes(doaTypesToSend, this.businessUnit).subscribe(
       (data) => {
         this.isLoading = false; 
         if (data) {
           this.isEditMode = false;
           this.originalCategories = JSON.parse(JSON.stringify(this.categories));
           this.router.navigate(['Home']);
         }
       },
       (error) => {
         this.isLoading = false; 
         console.error('Update failed:', error);
         console.error('Error details:', error.error); 
       }
     );
 } 
}



  }
}