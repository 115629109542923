import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportserviceService } from 'src/app/services/reportservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { IEmpDetails } from 'src/app/interfaces/reports/empdetails';


interface EmployeeDto {
    name: string;
}

@Component({
    selector: 'app-direct-delegation-admin',
    templateUrl: 'directdelegationadmin.component.html',
    styleUrls: ['./directdelegationadmin.component.css'],
    imports:[MatPaginatorModule]
})
export class DirectDelegationAdminComponent implements OnInit {
    title = 'Direct Delegation Admin';
    delegateFlag: boolean = false;
    supervisorEmail: string = '';
    employees: EmployeeDto[] = [];
    employee1: any = { temporaryDetails: [] };
    employee2: any = { masterDtos: [],doatype:[],delegations:[] };
    errorMessage: string = '';
    selectedEmployee: any;
    displayedColumns: string[] = ['doaType', 'amount', 'effectiveDate', 'statusCodeId', 'grantorEmail', 'supervisorEmail'];
    displayedColumns1: string[] = ['receivedFromCAI', 'receivedFromName', 'startDate', 'endDate'];
    displayedColumns2: string[] = ['empName', 'doaType', 'amount', 'effectiveDate', 'statusCodeId', 'grantorEmail', 'supervisorEmail'];
    dataSource = [];
    dataSource1 = [];
     //dataSource2 = new MatTableDataSource<any>([]);
    //dataSource2: MatTableDataSource<pendingdelegation>;
    dataSource2 = new MatTableDataSource<IEmpDetails>();
    employeeDetails: boolean = false;
    employeeName: string;
    currentBU: string;
    businessUnit: string = '';
    showDirectReportees: boolean = true; 
    showEmployeeDetails: boolean = true;
    loadingSupervisor: boolean = false; 
    loadingEmployee: boolean = false;   
    @ViewChild(MatPaginator) paginator: MatPaginator; 


    constructor(private employeeService: ReportserviceService) {this.dataSource2 = new MatTableDataSource<IEmpDetails>}

    ngOnInit(): void {
        const employeeInfo = JSON.parse(localStorage.getItem('employeeInfo') || '{}');
        if (employeeInfo && employeeInfo.businessUnit) {
            this.businessUnit = employeeInfo.businessUnit;
            this.currentBU = employeeInfo.businessUnit;
        }  
              
    }
    ngAfterViewInit() {
        this.dataSource2.paginator = this.paginator;
      }
    submitSupervisor() {
        if (!this.supervisorEmail) {
            this.errorMessage = 'Please enter a supervisor email.';
            return;
        }
        this.loadingSupervisor = true; 
        this.employeeService.getEmployeeData(this.supervisorEmail).subscribe(
            (data) => {
                this.loadingSupervisor = false;
                this.employees = data;
                // console.log(this.employees);
                if (this.employees.length === 0) {
                    this.errorMessage = 'No Data Available';
                } else {
                    this.errorMessage = '';
                }
            },
            (error) => {
                this.loadingSupervisor = false;
                this.errorMessage = 'Error fetching data';
                console.error(error);
            }
        );
    }

    toggleDirectReportees() {
        this.showDirectReportees = !this.showDirectReportees;
    }

    toggleEmployeeDetails() {
        this.showEmployeeDetails = !this.showEmployeeDetails;
    }

    onEmployeeClick(event: Event, selectedEmployee: any) {
        event.preventDefault();
        this.selectEmployee(selectedEmployee);
    }
    
    selectEmployee(selectedEmployee: any) {
        this.employeeDetails = true;
        this.employeeName = selectedEmployee.name;
        this.loadingEmployee = true; 
        this.employeeService.getEmployeeDetails(selectedEmployee.emailAddress, this.currentBU, selectedEmployee.supervisorEmail).subscribe(
            (data) => {
                this.employee2 = data;
                console.log(this.employee2);
                this.employee2.masterDtos.forEach((item, index, array) => {
                    if (item) {
                        let type = this.employee2.daoType.find((u) => u.id === item.typeCodeId && u.categoryId === item.catCodeId);
                        item.doaType = type ? type.typeName : 'Unknown';
                    } 
                });
                this.employee2.delegations.forEach((item, index, array) => {
                    if (item) {
                        if (item.statusCodeId === 1) {
                            item.statusCodeId = 'Submitted';
                        }
                    }
                 });
                this.dataSource1 = this.employee2.masterDtos;
                this.dataSource2 = new MatTableDataSource(this.employee2.delegations);
                this.delegateFlag = (this.employee2.delegations.length > 0)? true: false;

                this.dataSource2.paginator = this.paginator;

                if (this.dataSource1.length === 0) {
                    this.errorMessage = 'No Data Available';
                } else {
                    this.errorMessage = '';
                }
                this.loadingEmployee = false; 
            },
            (error) => {
                this.loadingEmployee = false; 
                this.errorMessage = 'Error fetching data';
                console.error(error);
            }
        );   
        
        this.employeeService.getDOAProfile(selectedEmployee.emailAddress).subscribe(
            (data) => {
                this.loadingEmployee = false; 
                this.employee1 = data;
                console.log(this.employee1);
                this.dataSource = this.employee1.temporaryDetails;
                if (this.dataSource.length === 0) {
                    this.errorMessage = 'No Data Available';
                } else {
                    this.errorMessage = '';
                }
            },
            (error) => {
                this.loadingEmployee = false; 
                this.errorMessage = 'Error fetching data';
                console.error(error);
            }
        );
    }
    applyFilter(filterValue: string, column: string) {
        this.dataSource2.filterPredicate = (data: IEmpDetails, filter: string) => {
            const transformedFilter = JSON.parse(filter);
            const dataValue = data[column] ? String(data[column]).toLowerCase() : '';
            return dataValue.includes(transformedFilter[column].toLowerCase());
        //   const transformedFilter = JSON.parse(filter);
        //   return data[column].toLowerCase().includes(transformedFilter[column].toLowerCase());
        };
        const filterObject = { [column]: filterValue };
        this.dataSource2.filter = JSON.stringify(filterObject);
      }
}

