import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMyProfile } from 'src/app/interfaces/doa/myprofile';
import { IDOAMasterDto } from 'src/app/interfaces/doa/permanentdoa';
import { environment } from 'src/environments/environment';

const localUrl = environment;
@Injectable({
  providedIn: 'root',
})
export class DelegationOfAuthorityService {
  constructor(private http: HttpClient) {}

  public getDOAProfile(email: string, currentBU: string): Observable<any> {
    let params = new HttpParams().set('email', email).set('currentBU', currentBU);
    return this.http.get<any>(DOAUrls.DOAProfile, { params });
  }

  public getUserProfile(email: string): Observable<any> {
    let params = new HttpParams().set('email', email);
    return this.http.get<any>(DOAUrls.userData, { params });
  }

  public getRedelegations(redelegationId: number, currentBU: string): Observable<any> {
    let params = new HttpParams().set('redelegationId', redelegationId).set('currentBU', currentBU);
    return this.http.get<any>(DOAUrls.redelegations, { params });
  }

  public getDoaDataByRequestId(requestId: number,currentBU:string):Observable<any> {
    return this.http.get<any[]>(DOAUrls.GetDoaDataByRequestId()+ `?requestId=${requestId}&currentBU=${currentBU}`);
  }
  public getDoaMasterByEmpEmailTypeCode(empEmail:string,typeCode:number,currentBU:string)
  {
    return this.http.get<any[]>(DOAUrls.GetDoaMasterByEmpEmailTypeCode()+`?&empEmail=${empEmail}&typeCode=${typeCode}&currentBU=${currentBU}`);
  }
  public getCategoryDOA(email:string):Observable<any> {
    return this.http.get<any[]>(DOAUrls.CategoryDOA()+ `?email=${email}`);
  }
  public getMasterData(currentBU:string,emailIds:string){
    return this.http.get(`${localUrl.apiUrl}/Admin/GetMasterList?currentBU=${currentBU}&emailIds=${emailIds}`);
  }
  public getPermanentDOAAttachments(currentBU:string,requestId:number){
    return this.http.get(`${localUrl.apiUrl}/Admin/GetPermanentDOAAdminAttachments?currentBU=${currentBU}&requestId=${requestId}`);
  }

  public submitPermanentDOAReq(obj:any,currentBU:string): Observable<any> {
    return this.http.post(`${localUrl.apiUrl}/DelegationOfAuthority/SubmitPermanentDoaRequest?currentBU=${currentBU}`,obj);
  }

  public revokeAuthority(obj:any,file?: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
    formData.append('file', file); // Append the single file
    }
    formData.append('data', JSON.stringify(obj)); 
    return this.http.post(`${localUrl.apiUrl}/Admin/RevokeAuthority`,formData);
    }
  

  public retainAuthority(RequestId:number,EmpEmail:string,TypeCode:number,currentBU:string,loggedUserEmail:string,file?: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file); // Append the single file
      }
    return this.http.post(`${localUrl.apiUrl}/Admin/RetainPermanentDOARequest?requestId=${RequestId}&empEmail=${EmpEmail}&typeCode=${TypeCode}&currentBU=${currentBU}&loggedUserEmail=${loggedUserEmail}`,formData);
    }
  
  public approveRequest(obj: any, currentBU: string, requestId: number, file?: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
    formData.append('file', file); // Append the single file
    }
    formData.append('data', JSON.stringify(obj)); 
  
    return this.http.post(`${localUrl.apiUrl}/Admin/ApprovePermanentDOARequest?currentBU=${currentBU}&requestId=${requestId}`, formData);
  }

  public modifyApproveRequest(obj:any,currentBU:string): Observable<any> {
    return this.http.post(`${localUrl.apiUrl}/Admin/ModifyApproveMasterDOAReq?currentBU=${currentBU}`,obj);
  }
  
   
  public rejectRequest(permanentrequestId:number,reason:string,currentBU:string,loggedUserEmail:string,file?: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file); // Append the single file
      }
      
    return this.http.post(`${localUrl.apiUrl}/Admin/RejectPermanentDOARequest?currentBU=${currentBU}&requestId=${permanentrequestId}&reason=${reason}&loggedUserEmail=${loggedUserEmail}`,formData);
  }

  public getDelegationOfAuthority(email: string) {
    return this.http.get(`${localUrl.apiUrl}/DelegationOfAuthority?email=${email}`);
  }
  public getTempDOADesignees(email: string, currentBU :string) {
    return this.http.get(`${localUrl.apiUrl}/DelegationOfAuthority/GetTempDOADesignees?email=${email}&currentBU=${currentBU}`);
  }
  public addTempDOADesignee(obj: any, currentBU: string) {
    return this.http.post(`${localUrl.apiUrl}/DelegationOfAuthority/AddTempDOADesignee?currentBU=${currentBU}`, obj);
  }
  public deleteTempDOADesignee(id: any, currentBU: string) {
    return this.http.delete(`${localUrl.apiUrl}/DelegationOfAuthority/DeleteTempDOADesignee?designeeId=${id}&currentBU=${currentBU}`);
  }

  public newTempDOA(obj: any, currentBU: string) {
    return this.http.post(`${localUrl.apiUrl}/DelegationOfAuthority/SubmitTemporaryDOA?currentBU=${currentBU}`, obj);
  }

  public tempAttachment(obj: any, currentBU: string) {
    return this.http.post(`${localUrl.apiUrl}/DelegationOfAuthority/TemporaryAttachment?currentBU=${currentBU}`, obj);
  }

  public deleteRedelegation(id: number, currentBU: string) {
    return this.http.delete(`${localUrl.apiUrl}/DelegationOfAuthority/DeleteRedelegations?redelegationId=${id}&currentBU=${currentBU}`);
  }
}

export const DOAUrls = {
  DOAProfile: `${localUrl.apiUrl}/DelegationOfAuthority/GetDOAProfile`,
  userData: `${localUrl.apiUrl}/DelegationOfAuthority`,  
  redelegations: `${localUrl.apiUrl}/DelegationOfAuthority/GetRedelegations`,
  CategoryDOA: () => `${localUrl.apiUrl}/ReferenceData`,
  PermanentDOA: () => `${localUrl.apiUrl}/DelegationOfAuthority/GetPermanentDOA`,
  SubmitPermanentDoaReq: ()=> `${localUrl.apiUrl}/DelegationOfAuthority/SubmitPermanentDoaReq`,
  GetDoaDataByRequestId: () => `${localUrl.apiUrl}/DelegationOfAuthority/GetDoaDataByRequestId`,
  GetDoaMasterByEmpEmailTypeCode:()=>`${localUrl.apiUrl}/DelegationOfAuthority/GetDOAMasterRequestByEmpEmailAndType?`
};
