// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-content {
    margin: auto !important;
    text-align: center;
}

.select-text
{
    color:white;
    background-color: #286090;
    text-align: left;
    padding-left:1%;
}
:host {
    font-family: "GothamBold", "Helvetica Neue", Helvetica,Arial, sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/app/doa/doaprofile/myprofile.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;;IAEI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,wEAAwE;EAC1E","sourcesContent":[".center-content {\n    margin: auto !important;\n    text-align: center;\n}\n\n.select-text\n{\n    color:white;\n    background-color: #286090;\n    text-align: left;\n    padding-left:1%;\n}\n:host {\n    font-family: \"GothamBold\", \"Helvetica Neue\", Helvetica,Arial, sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
