import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-doa-info-modal',
  template: `
    <div class="modal-header">
      <h2>{{ data.title }}</h2>
    </div>
    <div class="modal-content">
      <p>{{ data.modalMessage }}</p>
    </div>
    <div class="modal-actions">
      <button mat-button (click)="onClose()">OK</button>
    </div>
  `,
  styles: [`
    .modal-header {
      background-color: #0b2d7;
      color: white;
      padding: 10px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .modal-content {
      padding: 20px;
    }
    .modal-actions {
      padding: 10px;
      text-align: right;
    }
  `]
})
export class DoaInfoModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DoaInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
