import { Component, Input } from "@angular/core";

@Component({
  selector: "validation",
  templateUrl: "validation.component.html",
  styleUrls: ["validation.component.css"], // Add this line
})
export class ValidationComponent {
  @Input() ValidationList = [];
}
