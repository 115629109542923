// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-actions-right {
    display: flex;
    justify-content: flex-end;
  }
  .custom-button {
    margin-left: 10px; /* Adjust the gap between buttons */
    color: white; /* Text color */
    background-color: rgb(57, 57, 239); /* Button background color */
  }
  
  .custom-button:hover {
    background-color: rgb(57, 57, 239); /* Darker shade on hover */
  }`, "",{"version":3,"sources":["webpack://./src/app/admin/modifytempararydoa/success-dialog/success-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;EAC3B;EACA;IACE,iBAAiB,EAAE,mCAAmC;IACtD,YAAY,EAAE,eAAe;IAC7B,kCAAkC,EAAE,4BAA4B;EAClE;;EAEA;IACE,kCAAkC,EAAE,0BAA0B;EAChE","sourcesContent":[".dialog-actions-right {\n    display: flex;\n    justify-content: flex-end;\n  }\n  .custom-button {\n    margin-left: 10px; /* Adjust the gap between buttons */\n    color: white; /* Text color */\n    background-color: rgb(57, 57, 239); /* Button background color */\n  }\n  \n  .custom-button:hover {\n    background-color: rgb(57, 57, 239); /* Darker shade on hover */\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
