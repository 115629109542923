import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../auth.service'; 
 
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { 
    const roles = this.authService.getEmployeeRoles();
    const requiredRoles = route.data['roles'] as string[];
    const hasAccess = requiredRoles ? requiredRoles.some(role => roles.includes(role)) : true;
 
    if (!hasAccess) {
        this.router.navigate(['Home']);
      return false;
    }
    return true;
  }
}